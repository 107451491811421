// Google Font import
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

// Import functions and vars from Bootstrap v5.2.1
@import "functions";
@import "variables";
@import "maps";
@import "mixins";

// Default variable overrides
$font-size-base: 1.1rem;
$line-height-base: 1.6;
$font-weight-semibold: 500;
$font-weight-bold: 600;
$headings-font-weight: 500;
$btn-padding-x: 1.5rem;
$btn-padding-y: 0.75rem;
$btn-padding-x-lg: 2rem;
$btn-padding-y-lg: 0.75rem;
$gray-800: #161c27;
$gray-900: #0D1117;
$green-600: #117358;
$green-700: #0c5743;

// CSS variable overrides
@import "root";
:root {
    --bs-font-sans-serif: 'Poppins', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-body-bg: #010409;
    --bs-body-color: white;
    --bs-link-color: white;
    --bs-link-hover-color: #a3cfbb;
}

// Import the rest of what we need from Bootstrap
@import "utilities";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "helpers";
@import "utilities/api";
@import "buttons";

// Global
h2 {
    margin-top: ($spacer * 3);
}

h3, h4, h5 {
    margin-top: ($spacer * 2);
}

strong {
    font-weight: $font-weight-bold;
}

.text-small {
    font-size: $font-size-sm;
}

.section {
    padding: ($spacer * 2) 0;

    @include media-breakpoint-up(lg) { 
        padding: ($spacer * 5) 0;
    }
}

.section + .section {
    padding-top: 0;

    @include media-breakpoint-up(lg) { 
        padding-top: 0;
    }
}

.heading {
    margin-top: ($spacer * 3);
    font-size: $h2-font-size;
}

.section-header {
    margin-bottom: ($spacer * 2);

    &:after {
        content: '';
        background: url('../images/wiggle.svg') no-repeat;
        display: block;
        height: 8px;
        width: 100%;
        margin-top: 10px;
    }

    &.text-center:after {
        background-position: center;
    }
}

.panel {
    background: $gray-800;
    padding: ($spacer * 2);
    border-radius: $border-radius-xl;
    margin-bottom: ($spacer * 4);

    p:last-child {
        margin-bottom: 0;
    }

    .post-single & {
        margin-top: ($spacer * 4);
        margin-bottom: 0;
    }
}

.pt20 {
    margin-bottom: ($spacer * 2);
}

.pt10 {
    margin-bottom: calc($spacer / 2)
}

.podcastindex {
    li {
        margin-bottom: $spacer;
    }
}

// Page header
.page-header {
    padding: ($spacer * 2) 0;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__brand {
        display: block;
    }

    &__logo {
        width: auto;
        height: 70px;
    }
}

// Nav
.menu-btn {
    border: 0;
    color: white;
    background: $green-600;
    padding: calc($spacer / 2) $spacer;
    border-radius: $border-radius;
    font-weight: $font-weight-semibold;
    font-size: $font-size-lg;

    @include media-breakpoint-up(lg) { 
        display: none;
    }
}

.page-header__nav {
    display: none;

    @include media-breakpoint-up(lg) { 
        display: block;
    }
}

.page-header__nav.is-toggled {
    display: block;
    width: 100%;
    padding-bottom: ($spacer * 2);
    border-bottom: 2px solid $gray-800;

    @include media-breakpoint-up(lg) { 
        width: auto;
        border-bottom: 0;
    }
}

.navbar {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: ($spacer * 2) 0 0 0;
    padding: 0;

    &__item {
        margin: calc($spacer / 2) 0;
    }

    &__link {
        text-decoration: none;
        font-weight: $font-weight-semibold;
        font-size: $font-size-lg;
        border-bottom: 2px solid transparent;
    }

    &__link[aria-current] {
        border-bottom: 2px solid $green-600;
    }

    &__link svg {
        height: 24px;
        width: 24px;
        margin-right: 5px;
    }

    @include media-breakpoint-up(lg) { 
        flex-direction: row;
        margin: 0;

        &__item {
            margin: 0 0 0 ($spacer * 1);
            padding: 0;
        }

        &__link {
            text-decoration: none;
            font-weight: $font-weight-semibold;
            font-size: $font-size-lg;
            border-radius: $border-radius;
            padding: calc($spacer / 2) $spacer;
            border-bottom: 0;
        }

        &__link:hover {
            color: white;
            background: $green-600;
        }

        &__link[aria-current] {
            background: $green-600;
            border-bottom: 0;
        }
    }
}

// Buttons
.btn-geo {
    background: $green-600;
    font-weight: $font-weight-semibold;

    &:hover {
        background: $green-700!important;
    } 
}

.btn-geo.btn-lg {
    font-weight: $font-weight-semibold;
}

.btn-geo-card {
    margin-top: auto;
    background: $green-600;
    font-weight: $font-weight-semibold;

    &:hover {
        background: $green-700!important;
    } 
}

// Hero
.hero {
    padding: ($spacer * 2) 0;
    background: $gray-800;

    &__body {
        max-width: 600px;
    }

    p.fs-4 {
        line-height: 1.4;
    }

    .geo-card {
        margin-top: ($spacer * 2);
        background: rgba(0,0,0,0.4);
    }

    @include media-breakpoint-up(lg) { 
        padding: ($spacer * 5) 0;

        .geo-card {
            margin-top: 0;
        }
    }
}

// Custom card
.geo-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: $gray-800;
    padding: ($spacer * 2);
    border-radius: $border-radius-xl;

    @include media-breakpoint-up(lg) { 
        height: 100%;
    }
}

// Footer
.page-footer {
    border-top: 2px solid $gray-800;
    margin-top: ($spacer * 5);
    padding: ($spacer * 4) 0;

    &__inner {
        width: 100%;
        max-width: 300px;
        margin-bottom: ($spacer * 3);
    }

    &__logo {
        height: auto;
        width: 100px;
    }
}

.footer-nav {
    list-style: none;
    margin: 0;
    padding: 0;

    &__link {
        font-size: 0.9rem;
    }
}

// Post
.post--constrained {
    @include media-breakpoint-up(lg) { 
        max-width: 840px;
    }
}

.post-content ul {
    margin-top: $spacer;
}

.post-content h2:first-child {
    margin-top: 0;
}

.post-content img {
    @include img-fluid();
}

.figure--full {
    margin-top: ($spacer * 2);
    margin-bottom: ($spacer * 3);

    @include media-breakpoint-up(lg) { 
        width: calc(100% + 6rem);
        margin-left: -3rem;
        margin-right: -3rem;
    }
}

.breadcrumb {
    margin: 0;
}

// Sidebar
.sidebar__cta {
    margin-bottom: ($spacer * 2);
    width: 100%;
    max-width: 360px;
    margin-left: auto;
}

.sidebar__inner {
    background: $gray-800;
    padding: ($spacer * 2);
    border-radius: $border-radius-xl;
    width: 100%;
    max-width: 360px;
    margin-left: auto;
}

.subscribe-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.subscribe-list__item {
    margin: $spacer 0;
}

.subscribe-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: transparent;
    border-radius: 8px;
    color: white;
    padding: 0.7rem 0.8rem;
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    border: 1px solid rgba(255,255,255,0.25);
    transition: border 0.3s ease-in-out;

    &:hover {
        color: white;
        text-decoration: none;
        border: 1px solid rgba(255,255,255,0.75);
    }
}

.subscribe-btn svg {
    height: 25px;
    width: 25px;
    margin-right: 0.7rem;
}

// Podcast player
.castos-iframe-player {
    border: 0;
    height: 150px;
    width: 100%;
    margin-bottom: ($spacer * 2);
}

// Event list
.event-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.event-list__item {
    padding: 0.5em 0;

    &:last-child {
        border-bottom: 0;
    }
}

.event-list--past .event-list__link {
    color: #c1c1c1;

    &:hover {
        color: white;
    }
}

// Newsletter
// renamed mc_ to mailchimp_ because some adblockers don't like that CSS id
#mailchimp-embedded-subscribe-form {
    width: 100%;
    max-width: 400px;

    label {
        display: none;
    }
}

#mailchimp_embed_signup {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .form-input {
        font-size: $font-size-sm;
        padding: 0 8px;
        border-radius: $border-radius 0 0 $border-radius;
        height: 44px;
        border: 0;
        flex: 1;
    }

    .form-btn {
        font-size: $font-size-sm;
        background: $green-600;
        color: white;
        border: 0;
        height: 44px;
        padding: 0 20px;
        border-radius: 0 $border-radius $border-radius 0;
        font-weight: 500;
    }
}

// Sponsors
.sponsors {
    @include media-breakpoint-up(xl) { 
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.sponsors__link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid $gray-800;
    flex: 1;
    height: 140px;
    text-decoration: none;
    transition: 0.3s ease-in-out background;

    &:hover {
        color: white;
        background: $gray-800;
    }

    @include media-breakpoint-up(lg) { 
        border-right: 0;

        &:last-child {
            border-right: 1px solid $gray-800;
        }
    }
}

.sponsors__logo {
    display: block;
    width: auto;
    height: 44px;
}

.sponsors__logo--splashmaps {
    height: 30px;
}

.sponsors__logo--geolytix {
    height: 25px;
}

.sponsors__logo--bareways {
    height: 100px;
}

.sponsors__logo--edparsons {
    height: 65px;
}

.sponsors__logo--geovation {
    height: 35px;
}

.sponsors__logo--ucl {
    height: 50px;
}

.sponsors__label {
    display: block;
    margin-top: 4px;
    font-size: $font-size-sm;
}
